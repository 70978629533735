export interface AppUrls {
  auth: {
    login: string;
  };
  users: string;
  profile: string;
  diseases: string;
  healthFacilities: string;
  healthcareStaffs: string;
  info: string;
  results: string;
  printing: string;
  waitingResults: string;
  importedResults: string;
  positiveResults: string;
  brokenResults: string;
  errors: {
    notFound: string;
    server: string;
  };
  redirect: string;
  boards: string;
  lookup: string;
  statistics: {
    children: string;
    healthFacilities: string;
    units: string;
  };
}

export interface ApiEndpoints {
  auth: {
    login: string;
    logout: string;
    me: string;
    changePassword: string;
  };
  users: string;
  usernameValidation: string;
  districts: string;
  communes: string;
  ethnicities: string;
  diseases: string;
  diseaseValidation: string;
  healthFacilities: string;
  healthFacilityValidation: string;
  healthcareStaffs: string;
  patients: string;
  patientValidation: string;
  importResults: string;
  waitingResults: string;
  excelWaitingResults: string;
  importedResults: string;
  excelExportResults: string;
  returnResults: string;
  positiveResults: string;
  brokenResults: string;
  results: string;
  all: string;
  lookup: {
    byCode: string;
    byInfo: string;
  };
  statistics: {
    children: string;
    healthFacilities: string;
    units: string;
  };
}

// App module paths
// export const appDashboardsPath = 'dashboards';
export const appAuthPath = 'xac-thuc';
export const appLoginPath = 'dang-nhap';
export const appUsersPath = 'nguoi-dung';
export const appProfilePath = 'thong-tin-ca-nhan';
export const appDiseasesPath = 'cac-benh';
export const appHealthFacilitiesPath = 'cac-co-so-y-te';
export const appHealthcareStaffsPath = 'cac-nhan-vien-y-te';
export const appOriginalInformationPath = 'thong-tin-ban-dau';
export const appResultsPath = 'tra-ket-qua';
export const appPrintingPath = 'in-phieu';
export const appWaitingResultsPath = 'cho-ket-qua';
export const appImportedResultsPath = 'co-ket-qua';
export const appPositiveResultsPath = 'duong-tinh';
export const appBrokenResultsPath = 'mau-hong';
export const appErrorsPath = 'loi';
export const appBoardsPath = 'lua-chon';
export const appLookupPath = 'tra-cuu';
export const appStatisticsPath = 'thong-ke';
export const appStatisticsChildren = 'theo-dia-chi-tre';
export const appStatisticsHealthFacilities = 'theo-co-so-y-te';
export const appStatisticsUnits = 'theo-don-vi';

// Api paths
export const apiPrefix = 'api/v1'
export const apiAuth = 'auth';
export const apiLogin = 'login';
export const apiLogout = 'logout';
export const apiMe = 'me';
export const apiChangePassword = 'change-password';
export const apiUsers = 'users';
export const apiDistricts = 'districts';
export const apiCommunes = 'communes';
export const apiEthnicities = 'ethnicities';
export const apiDiseases = 'diseases';
export const apiHealthFacilities = 'health-facilities';
export const apiHealthcareStaffs = 'healthcare-staffs';
export const apiPatients = 'patients';
export const apiValidation = 'validate';
export const apiResults = 'results';
export const apiImportResults = 'results';
export const apiWaitingResults = 'results/waiting';
export const apiExcelWaitingResults = 'results/waiting/excel';
export const apiImportedResults = 'results/imported';
export const apiExcelExportResults = 'results/imported/excel';
export const apiReturnResults = 'return-results';
export const apiPositiveResults = 'results/positive';
export const apiBrokenResults = 'results/broken';
export const apiAll = 'all';
export const apiLookup = 'lookup';
export const apiLookupByCode = 'code';
export const apiLookupByInfo = 'personal-info';
export const apiStatistics = 'statistics';
export const apiStatisticsChildren = 'children';
export const apiStatisticsHealthFacilities = 'health-facilities';
export const apiStatisticsUnits = 'units';

export const appUrls: AppUrls = {
  auth: {
    login           : `/${appAuthPath}/${appLoginPath}`
  },
  users             : `/${appUsersPath}`,
  profile           : `/${appProfilePath}`,
  diseases          : `/${appDiseasesPath}`,
  healthFacilities  : `/${appHealthFacilitiesPath}`,
  healthcareStaffs  : `/${appHealthcareStaffsPath}`,
  info              : `/${appOriginalInformationPath}`,
  results           : `/${appResultsPath}`,
  printing          : `/${appPrintingPath}`,
  waitingResults    : `/${appWaitingResultsPath}`,
  importedResults   : `/${appImportedResultsPath}`,
  positiveResults   : `/${appPositiveResultsPath}`,
  brokenResults     : `/${appBrokenResultsPath}`,
  errors: {
    notFound        : `/${appErrorsPath}/404`,
    server          : `/${appErrorsPath}/500`
  },
  redirect          : `/${appUsersPath}`,
  boards            : `/${appBoardsPath}`,
  lookup            : `/${appLookupPath}`,
  statistics: {
    children          : `/${appStatisticsPath}/${appStatisticsChildren}`,
    healthFacilities  : `/${appStatisticsPath}/${appStatisticsHealthFacilities}`,
    units             : `/${appStatisticsPath}/${appStatisticsUnits}`
  },
};

export const apiEndpoints: ApiEndpoints = {
  auth: {
    login                   : `/${apiPrefix}/${apiAuth}/${apiLogin}`,
    logout                  : `/${apiPrefix}/${apiAuth}/${apiLogout}`,
    me                      : `/${apiPrefix}/${apiAuth}/${apiMe}`,
    changePassword          : `/${apiPrefix}/${apiAuth}/${apiMe}/${apiChangePassword}`
  },
  users                     : `/${apiPrefix}/${apiUsers}`,
  usernameValidation        : `/${apiPrefix}/${apiUsers}/${apiValidation}`,
  districts                 : `/${apiPrefix}/${apiDistricts}`,
  communes                  : `/${apiPrefix}/${apiCommunes}`,
  ethnicities               : `/${apiPrefix}/${apiEthnicities}`,
  diseases                  : `/${apiPrefix}/${apiDiseases}`,
  diseaseValidation         : `/${apiPrefix}/${apiDiseases}/${apiValidation}`,
  healthFacilities          : `/${apiPrefix}/${apiHealthFacilities}`,
  healthFacilityValidation  : `/${apiPrefix}/${apiHealthFacilities}/${apiValidation}`,
  healthcareStaffs          : `/${apiPrefix}/${apiHealthcareStaffs}`,
  patients                  : `/${apiPrefix}/${apiPatients}`,
  patientValidation         : `/${apiPrefix}/${apiPatients}/${apiValidation}`,
  importResults             : `/${apiPrefix}/${apiPatients}/${apiImportResults}`,
  waitingResults            : `/${apiWaitingResults}`,
  excelWaitingResults       : `/${apiExcelWaitingResults}`,
  importedResults           : `/${apiImportedResults}`,
  excelExportResults        : `/${apiExcelExportResults}`,
  returnResults             : `/${apiReturnResults}`,
  positiveResults           : `/${apiPositiveResults}`,
  brokenResults             : `/${apiBrokenResults}`,
  results                   : `/${apiPrefix}/${apiResults}`,
  all                       : `/${apiAll}`,
  lookup: {
    byCode                  : `/${apiPrefix}/${apiLookup}/${apiLookupByCode}`,
    byInfo                  : `/${apiPrefix}/${apiLookup}/${apiLookupByInfo}`
  },
  statistics: {
    children                : `/${apiPrefix}/${apiStatistics}/${apiStatisticsChildren}`,
    healthFacilities        : `/${apiPrefix}/${apiStatistics}/${apiStatisticsHealthFacilities}`,
    units                   : `/${apiPrefix}/${apiStatistics}/${apiStatisticsUnits}`
  },
};
